import Script from 'next/script'

export function GtmNoJs () {
    
    return (
        <noscript dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TF69SLG"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>` }}>
        </noscript>
    )
}

interface GtmScriptsProps {
    withNojs?: boolean
}

export default function GtmScripts ({ withNojs }: GtmScriptsProps) {
    withNojs = withNojs !== undefined ? withNojs : true
    
    return (
        <>
            <Script strategy="afterInteractive" 
                id="gtm-init"
                dangerouslySetInnerHTML={{ __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-TF69SLG');` }}
            ></Script>
            { withNojs === true && <GtmNoJs /> }
        </>
    )
}