import { getCsrfToken, getProviders, useSession } from 'next-auth/react'
// import { signIn } from 'next-auth/react'
import { SignInServerPageParams } from 'next-auth/core/pages/signin'
import { Input, Typography } from '@material-tailwind/react'
import { useRouter } from 'next/router'
import React, { ReactElement, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MainButton } from '../../components/buttons/MainButton'
import Link from 'next/link'
import AuthLayout from '../../components/layout/AuthLayout'
import { RequiredSubscriptionLevel } from '../_app'
import GtmScripts from '../../components/tracking/gtm'

export async function getServerSideProps() {
    const providers = await getProviders()
    return {
        props: {
            providers
        },
    }
}
 
function Password() {
    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = useState(false)

    const togglePassword = () => {
        setPasswordShown(!passwordShown)
    }

    return (
        <Input size="md" label="Passwort" type={passwordShown ? 'text' : 'password'} name="password"
            icon={<FontAwesomeIcon onClick={togglePassword} icon={['fas', 'eye']}/>}/>
    )
}

const LoginPresent = (props: any) => {
    if (props.signup == '1') {
        return (
            <Typography {...props} >
                Du hast bereits ein Konto ? <Link href={'/auth/signin'} className="underline">Zum Login</Link>
            </Typography>
        )
    }
    return (<Typography {...props} >

        Neu hier ? <Link href="/auth/register" className="underline">Jetzt Registrieren</Link>
    </Typography>)
}

const Signin = (props: SignInServerPageParams, signup: string) => {

    const { query } = useRouter()
    const { data: session, status } = useSession({ required: false })
    const [csrfToken, setCsrfToken] = useState('')

    useEffect(() => {
        async function fetchCsrfToken() {
            const result = await getCsrfToken()
            if (!result) {
                throw new Error('Can not sign in without a CSRF token')
            }
            setCsrfToken(result)
        }

        /*
          Wait until session is fetched before obtaining csrfToken
          to prevent synchronization issues caused by both
          /api/auth/session and /api/auth/csrf setting the cookie.
          Only happens in dev environment.
        */
        if (status !== 'loading') {
            fetchCsrfToken()
        }
    }, [status])

    const isRegister = signup == '1'

    let alerts = ''
    if (query.error) {
        if (query.error == 'OAuthAccountNotLinked') {
            alerts = 'Could not Link Account.'
        }
        else {
            alerts = 'Could not login. Please check your e-mail or password or third-party application.'
        }

    }
    if (session != null && session.user && !session.user.isConfirmed) {
        alerts = 'You need to confirm your email address before login.'
    }

    return (
        <AuthLayout title="Let's get started!" alerts={alerts}>

            {/*@ts-ignore*/}
            <form method="post" action={props.providers['wingmen'].callbackUrl}>
                <input name="csrfToken" type="hidden" defaultValue={csrfToken}/>
                <input name="isRegister" type="hidden" value={isRegister ? '1' : '0'}/>
                {/* while the dash is just an empty page, we're redirecting to the community page on login: */}
                <input name="callbackUrl" type="hidden" value={isRegister ? '/auth/confirm' : '/callback'}/>
                <div className="mb-10">
                    <Input size="md" label="E-Mail-Adresse" type="email" name="email"/>
                </div>
                <div className="mb-8">
                    <Password/>
                </div>

                <MainButton
                    className="bg-wing-gradient"
                    fullWidth
                    type="submit"
                >
                    {isRegister ? 'Registrieren' : 'Login'}
                </MainButton>
                <div className="flex items-center text-center justify-between flex-wrap gap-2 mt-6 ">
                    {isRegister ?
                        <LoginPresent color="white"
                            className="block w-full font-light text-wing-gray-200 text-sm text-center"
                            signup={signup}/>
                        :
                        <>
                            <Typography color="blue"
                                className="underline inline-block font-light text-wing-gray-200 text-sm text-center float-left">
                                <Link href='/auth/password/forgotten'>Passwort vergessen</Link>
                            </Typography>
                            <LoginPresent color="white"
                                className="inline-block font-light text-wing-gray-200 text-sm text-center"
                                signup={signup}/>
                        </>
                    }

                </div>

            </form>
            {/*temporarily disabling the login-with-discord functionality:*/}
            {/*{isRegister ?*/}
            {/*    <></> :*/}
            {/*    <>*/}
            {/*        <div className="w-full flex items-center gap-2 mt-6 mb-6 font-thin ">*/}
            {/*            <hr className="w-full border-wing-gray-200"/>*/}
            {/*            <Typography variant="small" className="uppercase text-sm">*/}
            {/*                oder*/}
            {/*            </Typography>*/}
            {/*            <hr className="w-full border-wing-gray-200"/>*/}
            {/*        </div>*/}
            {/*        <MainButton*/}
            {/*            onClick={() => signIn('discord', { callbackUrl: '/' })}*/}
            {/*            fullWidth*/}
            {/*        >*/}
            {/*            Sign in with Discord*/}
            {/*        </MainButton></>*/}
            {/*}*/}
        </AuthLayout>

    )
}
export default Signin

Signin.getLayout = (page: ReactElement) => {
    //the lessons have a completely different layout than most pages (no sidebar, no navigation, etc.)

    return (
        <div className="wing-page-container">
            {page}
            
            <GtmScripts />
        </div>
    )
}

Signin.getRequiredSubscriptionLevel = () => { return RequiredSubscriptionLevel.OpenForAll }